/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.faq-style-wrapper {
  margin-top: 2em;
}

.faq-style-wrapper .faq-title {
  font-size: x-large;
}

.faq-style-wrapper .faq-body {
  background-color: #272d3c;
}
.faq-style-wrapper .faq-body .faq-row {
  padding: 0.75em 0;
}

.faq-style-wrapper .faq-body .faq-row .row-title {
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 32px !important;
  line-height: 40px !important;
  /* padding:32px 40px !important; */
  color: #f6f6f6 !important;
}

.faq-style-wrapper .faq-body .faq-row .row-content-text {
  padding: 2em !important;
  font-size: medium !important;
  color: teal !important;
}

.slide-right {
  animation: 0.7s ease slide-right;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
.fade-in {
  animation: 4s fideIn;
}
@keyframes fideIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}


.align-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* .slide-down {
  animation: 0.7s ease slidDown;
}
@keyframes slidDown {
  0% {
    opacity: 0.7;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
} */


.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
}


/* Toggle */


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #30BCC2;
}

input:focus + .slider {
  box-shadow: 0 0 1px #30BCC2;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.MuiChipsInput-Chip{
  background: var(--chip-bg-color) !important;
border-radius: 100px !important;
color: var(--primary-text-color) !important;

}

.MuiChip-deleteIcon{
  color: var(--primary-subtext-color) !important;

}


