
:root{

  --background-image: url(assets/images/light.svg) ;
  --primary-text-color: #030711;
  --primary-border-color: #0F5370;
  --primary-subtext-color: #324B66;
  --button-background-color: #0F5370;
  --button-label-color: #DEEFF7;
  --textinput-background-color:#DEEFF7;
  --textinput-color:#324B66;
  --textinput-border-color: #91B6D1;
  --connectbutton-color:#91B6D1;
  --connectmenu-text-color:#0F5370;
  --conectmenu-hover-text-color:#030711;
  --accountmenu-background-color: #DEEFF7;
  --accountmenu-border-color: #91B6D1;
  --account-logo-color:#030711;
  --divider-background-color:#91B6D1;
  --modal-background-color:#DEEFF7;
  --wallet-hover-background-color:#BEDDEF;
  --wallet-border-color:#324B66;
  --outline-button-color:#0F5370;
  --offercard-background-color:rgba(190, 221, 239, 0.4);
  --offercard-border-color:#324B66;
  --exclusivecard-background-color:rgba(255, 163, 25, 0.2);
  --exclusivecard-border-color:#B44E00;
  --offercard-text-color:#324B66;
  --faq-background-color:rgba(145, 182, 209, 0.3);
  --tab-background-color:#91B6D1;
  --table-row-background-color:rgba(145, 182, 209, 0.4);
  --error-text-color:#9C1637;  
  --disconnect-text-color:#860C2A; 
   --skeleton-background-color:#BEDDEF;
   --chip-bg-color:#BEDDEF;
   --refer-bg-color: #18738B;
   --social-text-color:#324B66;
  --dappslogo-bg-color: #0F5370;
  --checkbox-bg-color: #0F5370;
  
  --partner-logo-bg-color:#192B49;
  --takenchip-bg-color: #782900;
  --takenchip-border-color: #FFE4E8;
  --filter-bg-color:#DEEFF7;
  

  --ownchip-bg-color:  #DEEFF7;
  

  
  
  /* --background: #DEEDF5; */
  /* --background:#DEEDF5; */
  
  /* --background: linear-gradient(330.04deg, rgba(25, 53, 107, 0) -23.85%, #19356B 20.84%, rgba(25, 53, 107, 0) 80.1%), linear-gradient(155.65deg, rgba(25, 53, 107, 0) -9.33%, #19356B 35.04%, rgba(25, 53, 107, 0) 59.46%); */
  
  
} 

.dark-theme{
  /* --background: linear-gradient(330.04deg, rgba(25, 53, 107, 0) -23.85%, #19356B 20.84%, rgba(25, 53, 107, 0) 80.1%), linear-gradient(155.65deg, rgba(25, 53, 107, 0) -9.33%, #19356B 35.04%, rgba(25, 53, 107, 0) 59.46%); */
  --background-image: url(assets/bg/BG\ -\ web.svg) ;
  --primary-text-color: #F7F7F7;
  --primary-border-color: #81ECDF;
  --primary-subtext-color: #90A7C0;
  --button-background-color: #81ECDF;
  --button-label-color: #030711;
  --textinput-background-color:#091430;
  --textinput-color:rgb(247, 247, 247);
  --textinput-border-color: #90A7C0;
  --connectbutton-color:#81ECDF;
  --connectmenu-text-color:#81ECDF;
  --conectmenu-hover-text-color:#030711;
  --accountmenu-background-color: #1D2A40;
  --accountmenu-border-color: #324B66;
  --account-logo-color:#f7f7f7;
  --divider-background-color:#4d657e;
  --modal-background-color:#1D2A40;
  --wallet-hover-background-color:#0f1e3b;
  --wallet-border-color:rgb(77, 101, 126);
  --outline-button-color:#81ecdf;
  --offercard-background-color:rgba(34, 45, 73, 0.54);
  --offercard-border-color:#4d657e;
  --exclusivecard-background-color:rgba(180,78,0,0.24);
  --exclusivecard-border-color: #FDC465;
  --offercard-text-color:#beddef;
  --faq-background-color:#272d3c;
  --tab-background-color:#222d49;
  --table-row-background-color:rgba(39,45,60,0.54);
  --error-text-color:#f38c7f;  
  --disconnect-text-color:#f38c7f;
  --skeleton-background-color:rgba(39, 45, 60, 0.54);
  --dappslogo-bg-color:#30BCC2;
  --refer-bg-color: #90A7C0;
  --social-text-color:rgba(0, 0, 0, 0.54);
  --checkbox-bg-color:#91B6D1;
  --partner-logo-bg-color:#ffffff;
  --chip-bg-color:#093D5D;
  --takenchip-bg-color: #860C2A;
  --takenchip-border-color: #fbbbab;
  --ownchip-bg-color: #2e3a4e;
  --filter-bg-color:#324b66;




  


  
}


.MuiMenu-paper{
  background: var(--accountmenu-border-color) !important;
/* Dark mode/Grey/Light */

border: 1px solid var(--connectbutton-color) !important;
box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08) !important;
border-radius: 8px !important;
color:var(--primary-text-color) !important;
}
input[type="checkbox"]{
  border-color: var(--primary-text-color) !important;
}

body {
  margin: 0;
  font-family: "Ubuntu" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-image);

  /* background:var(--background); */




  /* background: url(assets/bg/BG\ -\ web.svg); */
  /* background-color:linear-gradient(155.65deg, rgba(25, 53, 107, 0) -9.33%, #19356B 35.04%, rgba(25, 53, 107, 0) 59.46%), #030711; */
  /* background-color:var(--background); */
  /* background: linear-gradient(to right, #5c258d, #4389a2); */
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: overlay;
  /* transition: all 0.5s ease-in-out; */
  transition: all 0.5s linear;

}
@media(max-width:767px){
  body{
    min-height:100vh;
    background-size: auto;
  }
}
code {
  font-family: "Ubuntu" !important;
}




